import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5udq5on",
        "template_vlenoc3", 
        form.current, 
        "SQZFuXgpR6vJ9VbJm" 
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("E-mail enviado com sucesso!");
        },
        (error) => {
          console.log(error.text);
          alert("Erro ao enviar o e-mail. Tente novamente.");
        }
      );
  };

  return (
    <div id="contact" className="contact-page-wrapper">
      <h1 className="primary-heading">Como podemos ajudá-lo?</h1>
      <h1 className="primary-heading">Entre em contato</h1>
      <form ref={form} onSubmit={sendEmail} className="contact-form-container-two">
        <div className="contact-form-container">
          <input
            type="text"
            name="user_name"
            placeholder="Nome"
            required
          />
        </div>
        <div className="contact-form-container">
          <input
            type="text"
            name="user_phone" 
            placeholder="Telefone"
          />
        </div>
        <div className="contact-form-container">
          <input
            type="email"
            name="user_email" 
            placeholder="E-mail"
            required
          />
        </div>
        <div className="contact-form-container-textarea">
          <textarea
            name="message"
            placeholder="Mensagem"
            rows="4"
            required
          ></textarea>
        </div>
        <button type="submit" className="secondary-button">
          Enviar
        </button>
      </form>
    </div>
  );
};

export default Contact;
